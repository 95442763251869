<template lang="pug">
    .page.patients-edit
        h2 Редактирование пациента
        app-loader(v-if="pageLoading")
        .container.white-bg.border-r.shadow(v-else)
            app-horizontal-tabs(
                v-model="activeTabId"
                :tabs="tabs"
            )
            fixed-patient-panel(
                title="Редактирование пациента\n"
                :patient="patient"
            )
            component(
                v-for="tab in tabs"
                v-show="activeTabId === tab.id"
                :is="tab.component"
                :ref="tab.refName"
                :last-data="patient"
                :saveAndContinueLoading="saveAndContinueLoading"
                :saveLoading="saveLoading"
                :saveErrors="saveErrors"
                @clearSaveError="clearSaveError"
                :enable-atopic-dermatitis="enableAtopicDermatitis"
                @toggleEnableAtopicDermatitis="enableAtopicDermatitis=!enableAtopicDermatitis"
                :enable-bronchial-asthma="enableBronchialAsthma"
                @toggleEnableBronchialAsthma="enableBronchialAsthma=!enableBronchialAsthma"
                :enable-hao="enableHao"
                @toggleEnableHao="enableHao=!enableHao"
                :enable-hives="enableHives"
                @toggleEnableHives="enableHives=!enableHives"
                @nextStep="nextStep"
                @save="save"
                :addition-only="additionOnly"
            )
</template>

<script>
import AppHorizontalTabs from '@/components/app/app-horizontal-tabs/index';
import PatientData from '@/components/pages/patients/PatientData';
import AtopicDermatitis from '@/components/pages/patients/AtopicDermatitis';
import Asthma from '@/components/pages/patients/Asthma';
import Hao from '@/components/pages/patients/Hao';
import Hives from '@/components/pages/patients/Hives';
import Allergyanamnesis from '@/components/pages/patients/Allergyanamnesis';
import Anamnesis from '@/components/pages/patients/Anamnesis';
import Research from '@/components/pages/patients/Research';
import convertObjectToFormData from '@/js/utils/convertObjectToFormData';
import scrollToInvalidField from '@/js/utils/scrollToInvalidField';
import saveErrors from '@/mixins/saveErrors';
import FixedPatientPanel from '@/components/pages/patients/other/FixedPatientPanel';

export default {
    name: 'patients-edit',

    components: {
        FixedPatientPanel,
        AppHorizontalTabs,
        PatientData,
        AtopicDermatitis,
        Asthma,
        Hao,
        Hives,
        Allergyanamnesis,
        Anamnesis,
        Research,
    },

    mixins: [saveErrors],

    data: () => ({
        activeTabId: 0,

        enableAtopicDermatitis: false,
        enableBronchialAsthma: false,
        enableHao: false,
        enableHives: false,

        patient: {},

        pageLoading: false,
        saveAndContinueLoading: false,
        saveLoading: false,
    }),

    computed: {
        tabs() {
            return [
                {
                    id: 0,
                    name: 'Данные пациента',
                    component: 'PatientData',
                    refName: 'patientData',
                },
                {
                    id: 1,
                    name: 'Атопический дерматит',
                    component: 'AtopicDermatitis',
                    refName: 'atopicDermatitis',
                    disabled: !this.enableAtopicDermatitis,
                },
                {
                    id: 2,
                    name: 'Бронхиальная астма',
                    component: 'Asthma',
                    refName: 'asthma',
                    disabled: !this.enableBronchialAsthma,
                },
                {
                    id: 3,
                    name: 'Наследственный ангиоотек',
                    component: 'Hao',
                    refName: 'hao',
                    disabled: !this.enableHao,
                },
                {
                    id: 4,
                    name: 'Крапивница',
                    component: 'Hives',
                    refName: 'hives',
                    disabled: !this.enableHives,
                },
                {
                    id: 5,
                    name: 'Аллергоанамнез',
                    component: 'Allergyanamnesis',
                    refName: 'allergyanamnesis',
                },
                {
                    id: 6,
                    name: 'Анамнез жизни',
                    component: 'Anamnesis',
                    refName: 'anamnesis',
                },
                {
                    id: 7,
                    name: 'Исследования',
                    component: 'Research',
                    refName: 'research',
                },
            ];
        },

        user() {
            return this.$store.getters.user;
        },

        role() {
            return this.$store.getters.role;
        },

        additionOnly() {
            return this.role === 'DOCTOR' && this.user.id !== this.patient?.created_by;
        },
    },

    created() {
        this.fetchPatient();
        this.$store.dispatch('fetchRelatives');
        this.$store.dispatch('fetchAllergens');
        this.$store.dispatch('fetchMeasurements');
        this.$store.dispatch('fetchPeriods');
        this.$store.dispatch('fetchAdministrationMethods');
        this.$store.dispatch('fetchInjuries');
        this.$store.dispatch('fetchDrugs');
        this.$store.dispatch('fetchCancellationReasons');
        this.$store.dispatch('fetchCausesExacerbations');
        this.$store.dispatch('fetchUserList');
        this.$store.dispatch('fetchLaboratoryIndicators');
        this.$store.dispatch('fetchProvocativeTests');
        this.$store.dispatch('fetchFunctionalTests');
        this.$store.dispatch('fetchSpecificExaminations');
        this.$store.dispatch('fetchSpecialProcedures');
    },

    methods: {
        nextStep() {
            this.activeTabId = this.tabs.slice(this.activeTabId+1).find(t => !t.disabled).id;
            setTimeout(() => {
                this.$scrollTo({ y: 0 });
            });
        },

        async save(args) {
            const isContinue = args?.isContinue || false;

            for (let t of this.tabs) {
                if (!this.$refs[t.refName][0].checkValid()) {
                    this.activeTabId = t.id;
                    setTimeout(scrollToInvalidField);
                    return;
                } else {
                    console.log(t);
                }
            }

            this.saveErrors = {};

            if (isContinue) {
                if (this.saveAndContinueLoading) return;
                this.saveAndContinueLoading = true;
            } else {
                if (this.saveLoading) return;
                this.saveLoading = true;
            }

            try {
                let fd = {
                    ...this.$refs.patientData[0].getFormFields(),
                    ...this.$refs.atopicDermatitis[0].getFormFields(),
                    ...this.$refs.asthma[0].getFormFields(),
                    ...this.$refs.hao[0].getFormFields(),
                    ...this.$refs.hives[0].getFormFields(),
                    ...this.$refs.allergyanamnesis[0].getFormFields(),
                    ...this.$refs.anamnesis[0].getFormFields(),
                    ...this.$refs.research[0].getFormFields(),
                    version: this.patient.version,
                };
                const { data } = await this.axios.post(`patients/${this.$route.params.id}`, convertObjectToFormData(fd));
                this.patient.version = data.version;
                if (isContinue) {
                    this.nextStep();
                } else {
                    this.$router.push({ name: 'Patients' });
                }
            } catch (e) {
                console.log(e);
                this.setSaveErrors(e?.response);
            } finally {
                if (isContinue) {
                    this.saveAndContinueLoading = false;
                } else {
                    this.saveLoading = false;
                }
            }
        },

        async fetchPatient() {
            this.pageLoading = true;
            try {
                const { data } = await this.axios.get(`patients/${this.$route.params.id}`);
                this.patient = data;
                if (data.sicknesses?.find(s => s.type === 'DERMATITIS')) {
                    this.enableAtopicDermatitis = true;
                }
                if (data.sicknesses?.find(s => s.type === 'ASTHMA')) {
                    this.enableBronchialAsthma = true;
                }
                if (data.sicknesses?.find(s => s.type === 'HEREDITARY_ANGIOOTEK')) {
                    this.enableHao = true;
                }
                if (data.sicknesses?.find(s => s.type === 'URTICARIA_MAIN')) {
                    this.enableHives = true;
                }
            } catch (e) {
                this.$router.replace('/not-found');
            } finally {
                this.pageLoading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.patients-edit {
    padding-bottom: rem(20px);

    & > .container {
        position: relative;
    }

    @include mobile_or_P {
        padding-bottom: 0;
    }
}
</style>
