export default {
    props: {
        lastData: {
            type: Object,
            required: false,
        },

        enableAtopicDermatitis: {
            type: Boolean,
            default: false,
        },

        enableBronchialAsthma: {
            type: Boolean,
            default: false,
        },

        enableHao: {
            type: Boolean,
            default: false,
        },

        enableHives: {
            type: Boolean,
            default: false,
        },

        saveAndContinueLoading: {
            type: Boolean,
            required: false,
            default: false,
        },

        saveLoading: {
            type: Boolean,
            required: false,
            default: false,
        },

        saveErrors: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },

    data: () => ({
        hasDataFromLocalStorage: false,
    }),

    methods: {
        saveAndContinue() {
            if (this.$route.name === 'PatientsNew') {
                this.setDataToLocalStorage('new-patient-data');
                this.$emit('nextStep');
            } else if (this.$route.name === 'PatientsEdit') {
                this.$emit('save', { isContinue: true });
            } else if (this.$route.name === 'PatientsReContact') {
                this.setDataToLocalStorage(`re-contact-patient-data-${this.$route.params.id}`);
                this.$emit('nextStep');
            }
        },

        setDataToLocalStorage(key) {
            let data = {};
            let dataFromLocalStorage = localStorage.getItem(key);
            if (dataFromLocalStorage) {
                data = JSON.parse(dataFromLocalStorage);
            }
            data[this.$options.name] = this.$data;

            data.enableAtopicDermatitis = this.enableAtopicDermatitis;
            data.enableBronchialAsthma = this.enableBronchialAsthma;
            data.enableHao = this.enableHao;
            data.enableHives = this.enableHives;

            localStorage.setItem(key, JSON.stringify(data));
        },

        getDataFromLocalStorage(key) {
            let dataFromLocalStorage = localStorage.getItem(key);
            if (dataFromLocalStorage) {
                let tabs = JSON.parse(dataFromLocalStorage);

                if (this.$options.name === 'patient-data' && this.enableAtopicDermatitis !== tabs.enableAtopicDermatitis) {
                    this.$emit('toggleEnableAtopicDermatitis');
                }
                if (this.$options.name === 'patient-data' && this.enableBronchialAsthma !== tabs.enableBronchialAsthma) {
                    this.$emit('toggleEnableBronchialAsthma');
                }
                if (this.$options.name === 'patient-data' && this.enableHao !== tabs.enableHao) {
                    this.$emit('toggleEnableHao');
                }
                if (this.$options.name === 'patient-data' && this.enableHives !== tabs.enableHives) {
                    this.$emit('toggleEnableHives');
                }

                let currentTab = tabs[this.$options.name];
                if (currentTab) {
                    for (let fieldName in currentTab) {
                        this[fieldName] = currentTab[fieldName];
                    }
                    this.hasDataFromLocalStorage = true;
                }
            }
        },

        saveAndClose() {
            this.$emit('save');
        },

        checkValid() {
            if (this.$v?.$invalid) {
                this.$v.$touch();
                return false;
            }
            return true;
        },

        close() {
            if (this.$route.name === 'PatientsNew') {
                localStorage.removeItem('new-patient-data');
            } else if (this.$route.name === 'PatientsReContact') {
                localStorage.removeItem(`re-contact-patient-data-${this.$route.params.id}`);
            }
            this.$router.push({ name: 'Patients' });
        },
    },

    created() {
        if (this.$route.name === 'PatientsNew') {
            this.getDataFromLocalStorage('new-patient-data');
        } else if (this.$route.name === 'PatientsReContact') {
            this.getDataFromLocalStorage(`re-contact-patient-data-${this.$route.params.id}`);
        }
    },
};
