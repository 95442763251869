<template lang="pug">
    .diagnoses-block(:class="{'diagnoses-block--re-contact': isReContact}")
        .diagnoses-block__row(
            v-for="(item, index) of diagnoses"
            v-show="!isReContact || !item.old || item.id === lastOldDiagnosis.id"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-text-field(
                    v-model="item.name"
                    label="Диагноз полностью"
                    is-textarea
                    :error="getFieldError(item, index, 'name')"
                    :disabled="(isReContact || additionOnly) && item.old"
                )
                .row.column
                    .row
                        app-datepicker(
                            v-model="item.dateOfDiagnosis"
                            :inaccurate="item.dateOfDiagnosisInaccurate"
                            label="Установка диагноза"
                            :disabled-dates="disabledDates"
                            :error="getFieldError(item, index, 'dateOfDiagnosis')"
                            :disabled="(isReContact || additionOnly) && item.old"
                        )
                        .app-choice-group.app-choice-group--center
                            app-choice(
                                v-model="item.dateOfDiagnosisInaccurate"
                                type="checkbox"
                                label="Неточная дата <small>(если месяц неизвестен, укажите январь)</small>"
                                :disabled="(isReContact || additionOnly) && item.old"
                            )
                    .row(v-if="item.dateOfFirstSymptoms || !diagnoses.some(d => d.dateOfFirstSymptoms) && index === 0")
                        app-datepicker(
                            v-model="item.dateOfFirstSymptoms"
                            :inaccurate="item.dateOfFirstSymptomsInaccurate"
                            label="Первые симптомы"
                            :error="getFieldError(item, index, 'dateOfFirstSymptoms')"
                            :disabled="(isReContact || additionOnly) && item.old"
                        )
                        .app-choice-group.app-choice-group--center
                            app-choice(
                                v-model="item.dateOfFirstSymptomsInaccurate"
                                type="checkbox"
                                label="Неточная дата <small>(если месяц неизвестен, укажите январь)</small>"
                                :disabled="(isReContact || additionOnly) && item.old"
                            )
                app-dropper(
                    :class="{hidden: (required && diagnoses.length === 1) || (isReContact || additionOnly) && item.old}"
                    @click="dropDiagnosis(index)"
                )
            .row(v-if="withAsthmaForm")
                .app-choice-group
                    .app-choice-group__label Форма бронхиальной астмы
                    .app-choice-group__list
                        app-choice(
                            v-for="form in item.forms"
                            v-model="form.value"
                            type="checkbox"
                            :label="form.name"
                            :error="getFieldError(item, index, 'forms')"
                            :disabled="(isReContact || additionOnly) && item.old"
                        )
            .row(v-if="withHaoTypes")
                .app-choice-group
                    .app-choice-group__label Тип наследственного ангиоотека
                    .app-choice-group__list
                        app-choice(
                            v-for="type in HAO_TYPES"
                            v-model="item.haoType"
                            type="radio"
                            :radio-value="type.id"
                            :label="type.name"
                            :error="getFieldError(item, index, 'haoType')"
                            :disabled="(isReContact || additionOnly) && item.old"
                        )
            hr(v-if="index < diagnoses.length - 1")
        .br
        app-button(
            plus
            @click="addNewDiagnosis"
        ) Добавить диагноз
</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { ASTHMA_FORMS, HAO_TYPES } from '@/js/const';

export default {
    name: 'diagnoses-block',

    props: {
        value: {
            type: Array,
            required: true,
        },

        required: {
            type: Boolean,
            required: false,
            default: false,
        },

        withAsthmaForm: {
            type: Boolean,
            required: false,
            default: false,
        },

        withHaoTypes: {
            type: Boolean,
            required: false,
            default: false,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        diagnoses: [],
        HAO_TYPES,
    }),

    validations: {
        diagnoses: {
            $each: {
                name: { required },
                dateOfDiagnosis: {
                    required,
                    uniqual(value, item) {
                        return !this.diagnoses.some(d => d.id !== item.id && value && d.dateOfDiagnosis === value);
                    },
                },
                dateOfFirstSymptoms: {
                    required() {
                          return this.diagnoses.some(d => d.dateOfFirstSymptoms);
                    },
                },
                forms: {
                    required(value) {
                        return value.some(f => f.value) || !this.withAsthmaForm;
                    },
                },
                haoType: {
                    required(value) {
                        return value || !this.withHaoTypes;
                    },
                },
            },
        },
    },

    computed: {
        disabledDates() {
            return this.diagnoses.map(e => e.dateOfDiagnosisInaccurate ? 0 : e.dateOfDiagnosis);
        },

        lastOldDiagnosis() {
            return this.diagnoses.reduce((lastD, d) => {
                if (d.old && (!lastD ||d.dateOfDiagnosis > lastD.dateOfDiagnosis)) {
                    lastD = d;
                }
                return lastD;
            }, null);
        },
    },

    watch: {
        diagnoses(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            if (this.value.length) {
                this.diagnoses = this.value;
            } else if (this.required) {
                this.addNewDiagnosis();
            }
        });
    },

    methods: {
        addNewDiagnosis() {
            this.diagnoses.push({
                id: uniqueId(),
                name: '',
                dateOfDiagnosis: '',
                dateOfDiagnosisInaccurate: false,
                dateOfFirstSymptoms: '',
                dateOfFirstSymptomsInaccurate: false,
                forms: ASTHMA_FORMS.map(f => ({ ...f, value: false })),
                haoType: undefined,
            });
            this.$v.$reset();
        },

        dropDiagnosis(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить диагноз?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.diagnoses = this.diagnoses.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.diagnoses.$each.$iter[index].$dirty) {
                if (!this.$v.diagnoses.$each.$iter[index][field].required) {
                    return 'Заполните поле';
                } else if (field === 'dateOfDiagnosis' && !this.$v.diagnoses.$each.$iter[index].dateOfDiagnosis.uniqual) {
                    return 'Значение должно быть уникальным';
                }
            }
            return null;
        },

        getFieldsForSave() {
            return this.diagnoses.map(d => {
                const obj = {
                    data: d.name,
                    diagnosis_date: moment(d.dateOfDiagnosis).format('YYYY-MM-DD'),
                    diagnosis_date_inaccurate: +d.dateOfDiagnosisInaccurate,
                };
                if (d.dateOfFirstSymptoms) {
                    obj.first_symptoms_date = moment(d.dateOfFirstSymptoms).format('YYYY-MM-DD');
                    obj.first_symptoms_date_inaccurate = +d.dateOfFirstSymptomsInaccurate;
                }
                if (this.withAsthmaForm) {
                    obj.asthma_forms = d.forms.filter(f => f.value).map(f => f.id);
                }
                if (this.withHaoTypes) {
                    obj.hao_types = [d.haoType];
                }
                return obj;
            });
        },

        getFormattedSavedFields(diagnoses) {
            let fields = diagnoses.map(d => {
                const obj = {
                    id: d.id,
                    name: d.data,
                    dateOfDiagnosis: +moment(d.diagnosis_date),
                    dateOfDiagnosisInaccurate: d.diagnosis_date_inaccurate,
                    dateOfFirstSymptoms: d.first_symptoms_date ? +moment(d.first_symptoms_date) : '',
                    dateOfFirstSymptomsInaccurate: d.first_symptoms_date_inaccurate,
                    forms: ASTHMA_FORMS.map(f => ({
                        ...f,
                        value: Boolean(d.asthma_forms.find(af => Number(af.id) === Number(f.id))),
                    })),
                    haoType: d.hao_types[0]?.id,
                    old: true,
                };
                return obj;
            });
            fields.sort((f1, f2) => f1.dateOfDiagnosis - f2.dateOfDiagnosis);
            return fields;
        },
    },
};
</script>

<style lang="scss">
.diagnoses-block {
    .app-datepicker {
        flex-shrink: 0;

        width: rem(215px);
    }

    .app-text-field textarea {
        min-height: rem(120px);
    }

    .app-choice label small {
        max-width: 9rem;
    }

    &--re-contact {
        hr {
            margin-bottom: 0;
        }

        .diagnoses-block__row {
            margin: 0 -1rem 0 -1rem;
            padding: 2rem 1rem 0;

            &:first-child {
                padding-top: 1rem;
            }

            &.old {
                background: $light-blue;
            }
        }
    }

    @include mobile_or_P {
        .app-datepicker {
            width: 100%;
        }

        .app-choice label small {
            max-width: 100%;
        }
    }
}
</style>
