<template lang="pug">
    .hao
        h4 Диагноз
        diagnoses-block(
            v-model="diagnoses"
            ref="diagnoses"
            required
            with-hao-types
            :addition-only="additionOnly"
        )

        h4 Таргентная терапия
        target-therapy-block(
            v-model="targetTherapy"
            ref="targetTherapy"
            :addition-only="additionOnly"
        )

        h4 Базисная терапия
        therapy-block(
            v-model="basicTherapy"
            ref="basicTherapy"
            :addition-only="additionOnly"
        )

        h4 Обострения
        exacerbations-block(
            v-model="exacerbations"
            ref="exacerbations"
            with-cause
            nebulizer
            :addition-only="additionOnly"
        )

        h4 Нежелательные эффекты
        unwanted-effects-block(
            v-model="unwantedEffects"
            ref="unwantedEffects"
            :addition-only="additionOnly"
        )

        h4 Опросники
        questionnaires-angiooteki(
            v-model="questionnairesHao"
            ref="questionnairesHao"
            :addition-only="additionOnly"
        )

        .row.sticky-buttons-block
            app-button(
                large
                :loading="saveAndContinueLoading"
                @click="saveAndContinue"
            ) Сохранить и продолжить заполнение
            app-button(
                :loading="saveLoading"
                large
                color="white"
                @click="saveAndClose"
            ) Сохранить и закончить заполнение
            app-link.app-link--red.app-link--left-margin(@click="close") Закрыть без сохранения
</template>

<script>
import TherapyBlock from '@/components/pages/patients/blocks/TherapyBlock';
import TargetTherapyBlock from '@/components/pages/patients/blocks/TargetTherapyBlock';
import DiagnosesBlock from '@/components/pages/patients/blocks/DiagnosesBlock';
import ExacerbationsBlock from '@/components/pages/patients/blocks/ExacerbationsBlock';
import saving from '@/mixins/saving';
import UnwantedEffectsBlock from '@/components/pages/patients/blocks/UnwantedEffectsBlock';
import QuestionnairesAngiooteki from '@/components/pages/patients/questionnaires/QuestionnairesAngiooteki';

export default {
    name: 'hao',

    components: {
        TherapyBlock,
        TargetTherapyBlock,
        DiagnosesBlock,
        ExacerbationsBlock,
        UnwantedEffectsBlock,
        QuestionnairesAngiooteki,
    },

    mixins: [saving],

    props: {
        additionOnly: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        diagnoses: [],
        targetTherapy: [],
        basicTherapy: [],
        exacerbations: [],
        unwantedEffects: [],
        questionnairesHao: [],
    }),

    mounted() {
        if (this.lastData && !this.hasDataFromLocalStorage) {
            const hao = this.lastData.sicknesses.find(s => s.type === 'HEREDITARY_ANGIOOTEK');
            if (hao) {
                this.diagnoses = this.$refs.diagnoses.getFormattedSavedFields(hao.diagnoses);

                this.targetTherapy = this.$refs.targetTherapy.getFormattedSavedFields(hao.target_therapies);

                this.basicTherapy = this.$refs.basicTherapy.getFormattedSavedFields(hao.basis_therapies);

                this.exacerbations = this.$refs.exacerbations.getFormattedSavedFields(hao.exacerbations);

                this.unwantedEffects = this.$refs.unwantedEffects.getFormattedSavedFields(hao.unwanted_effects);

                this.questionnairesHao = this.$refs.questionnairesHao.getFormattedSavedFields(hao.questionnaires);
            }
        }
    },

    methods: {
        getFormFields() {
            if (!this.enableHao) {
                return {};
            }
            return {
                hereditary_angiootek: {
                    diagnoses: this.$refs.diagnoses.getFieldsForSave(),
                    target_therapies: this.$refs.targetTherapy.getFieldsForSave(),
                    basis_therapies: this.$refs.basicTherapy.getFieldsForSave(),
                    exacerbations: this.$refs.exacerbations.getFieldsForSave(),
                    unwanted_effects: this.$refs.unwantedEffects.getFieldsForSave(),
                    questionnaires: this.$refs.questionnairesHao.getFieldsForSave(),
                },
            };
        },

        checkValid() {
            if (!this.enableHao) {
                return true;
            }
            if (this.$refs.diagnoses.$v.$invalid
                || this.$refs.targetTherapy.$v.$invalid
                || this.$refs.basicTherapy.$v.$invalid
                || this.$refs.exacerbations.$v.$invalid
                || this.$refs.unwantedEffects.$v.$invalid
                || this.$refs.questionnairesHao.$v.$invalid) {
                this.$refs.diagnoses.$v.$touch();
                this.$refs.targetTherapy.$v.$touch();
                this.$refs.basicTherapy.$v.$touch();
                this.$refs.exacerbations.$v.$touch();
                this.$refs.unwantedEffects.$v.$touch();
                this.$refs.questionnairesHao.$v.$touch();
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="scss">
.hao {
    @include mobile_or_P {
        max-width: 100vmin;
        margin: 0 auto;

        h4 {
            margin-bottom: 1rem;
        }
    }
}
</style>
