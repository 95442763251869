<template lang="pug">
    .app-horizontal-tabs(ref="app-horizontal-tabs")
        .app-horizontal-tabs__wrapper
            .app-horizontal-tabs__item(
                v-for="tab in tabs"
                :class="{active: tab.id === value, disabled: tab.disabled}"
                @click="$emit('input', tab.id)")
                span(v-html="tab.name")
            .app-horizontal-tabs__line
                .app-horizontal-tabs__indicator(:style="indicatorStyles")
</template>

<script>

export default {
    name: 'app-horizontal-tabs',

    props: {
        value: {
            type: [Number, String],
            required: true,
        },

        tabs: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        lastLeft: 0,
        indicatorStyles: {},
        scrollBar: null,
    }),

    watch: {
        value() {
            this.$nextTick(() => {
                this.updateIndicatorStyles();

                const activeTab = this.$refs['app-horizontal-tabs'].querySelector('.app-horizontal-tabs__item.active');
                this.scrollBar?.scrollTo(activeTab.offsetLeft, 0, 300);
            });
        },
    },

    mounted() {
        this.updateIndicatorStyles();

        this.scrollBar = window.Scrollbar.init(this.$refs['app-horizontal-tabs'], {
            alwaysShowTracks: false,
            continuousScrolling: false,
        });
    },

    methods: {
        updateIndicatorStyles() {
            const activeTab = this.$refs['app-horizontal-tabs'].querySelector('.app-horizontal-tabs__item.active');
            const width = activeTab.offsetWidth;
            const left = activeTab.offsetLeft;
            let transition = 'left .2s, right .2s .1s';
            if (this.lastLeft < left) {
                transition = 'left .2s .1s, right .2s';
            }
            this.lastLeft = left;
            this.indicatorStyles =  {
                left: `${left}px`,
                right: `calc(100% - ${left}px - ${width}px)`,
                transition,
            };
        },
    },
};
</script>
